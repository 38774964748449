import React from "react";
import Category from "../../components/Category/Category";

function CategoryScreen() {
  return (
    <>
      <Category />
    </>
  );
}

export default CategoryScreen;
