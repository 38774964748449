import React from "react";
import Contact from "../../components/Contact/Contact";

function ContactScreen() {
  return (
    <>
      <Contact />
    </>
  );
}

export default ContactScreen;
