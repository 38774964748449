import React from "react";
import About from "../../components/About/About";

function AboutScreen() {
  return (
    <>
      <About />
    </>
  );
}

export default AboutScreen;
